<template>
  <BaseCard
    title="datos de la obra"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <ProjectProfileForm
          ref="project-profile-form"
          v-model="project"
        />
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <ProjectResponsibleForm
          ref="project-responsible-form"
          v-model="project"
        />
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ProjectProfileForm from '@/components/projects/form/ProjectProfileForm.vue'
import ProjectResponsibleForm from '@/components/projects/form/ProjectResponsibleForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ProjectResponsibleForm,
    ProjectProfileForm,
  },
  data() {
    return {
      loading: false,
      project: {},
    }
  },
  computed: {
    clientId() {
      return this.$route.query['client-id']
    },
    profileForm() {
      return this.$refs['project-profile-form']
    },
  },
  mounted() {
    this.loadClient()
  },
  methods: {
    async loadClient() {
      if (!this.clientId) {
        return
      }

      try {
        this.loading = true
        const response = await ApiRestService.get(this.clientId, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        this.project = { ...this.project, client: response.data }
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const profileValid = await this.profileForm.validateForm()
      const responsibleValid = await this.$refs['project-responsible-form'].validateForm()

      if (!profileValid || !responsibleValid) {
        return
      }

      this.loading = true
      try {
        const { documentation } = this.profileForm.getDocumentationData()
        const response = await ApiRestService.create({ ...this.project, documentation }, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
        const newProject = response.data
        await this.$router.replace({ name: 'viewProject', params: { id: newProject.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
